import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

import Layout from "../../components/SnickersNFLComponenets/components/layout"

const OfficialRules = () => (
  <Layout>
    <Helmet>
        <link rel="stylesheet" href="../../SnickersNFL/css/app.css" />
        <link rel="stylesheet" href="../../SnickersNFL/css/responsive.css" />
      </Helmet>
    <div className="text-start content-hldr">
      <div class="page-heading">
        <h1 class="text-uppercase text-lg">Official Rules</h1>
      </div>
      <div class="content">
        <p>
          NO PURCHASE NECESSARY TO ENTER. A PURCHASE WILL NOT INCREASE YOUR
          CHANCES OF WINNING. A VALID EMAIL ADDRESS IS REQUIRED. PLEASE READ
          THESE OFFICIAL RULES CAREFULLY AS THE OFFICIAL RULES REQUIRE THAT
          ENTRANT WAIVES THE RIGHT TO A JURY TRIAL AND THAT DISPUTES ARISING OUT
          OF OR RELATING TO THIS PROMOTION BE RESOLVED IN ARBITRATION ON AN
          INDIVIDUAL BASIS, RATHER THAN BY CLASS ACTIONS, AND LIMIT YOUR RIGHTS
          AND REMEDIES IN THE EVENT OF A DISPUTE.
        </p>
        <p>
          1. ELIGIBILITY: The Mars Wrigley Confectionery CANDY CALL IT™
          Promotion (the “Promotion”) is offered only to legal residents of the
          50 United States and the District of Columbia, who are 16 years of age
          or older at the time of entry. Void where prohibited or restricted by
          law. Employees, officers and directors, and their immediate family
          members (spouses, siblings, parents and children, including foster and
          step-relations, and their respective spouses) and household members
          (whether or not related) of Mars Wrigley Confectionery US, LLC
          (“Sponsor”), TPG Rewards, Inc. (“Prize Administrator”), NFL Entities
          (as defined below), MARS Advertising, Inc. d/b/a the Mars Agency
          (“Administrator”), their respective owners, parents, subsidiaries and
          affiliates, advertising and promotion agencies, and any companies
          involved in the implementation and execution of the Promotion
          (collectively, the “Promotion Entities”) are not eligible. By
          participating, each entrant fully and unconditionally agrees to and
          accepts these Official Rules and the decisions of the Sponsor and
          Administrator, which are final and binding on all matters relating to
          the Promotion.
        </p>
        <p>
          2. NFL ENTITIES: The National Football League, its member professional
          football clubs, NFL Ventures, Inc., NFL Ventures, L.P., NFL Properties
          LLC, NFL Enterprises LLC and each of their respective subsidiaries,
          affiliates, owners, shareholders, officers, directors, agents,
          representatives and employees (collectively, the “NFL Entities”).
        </p>
        <p>
          3. PROMOTION PERIOD: Promotion starts on or about 12:00:01 a.m.
          Eastern Time (ET) on September 1, 2021 and ends approximately thirty
          (30) minutes before the NFL Sunday Night Game kickoff on October 31,
          2021 (“Promotion Period”). The clock on Promotion Administrator’s
          server shall be the official time keeping device for this Promotion.
        </p>
      </div>

      <div className="peanut-hldr">
        <figure className="text-center">
          <StaticImage src="../../components/SnickersNFLComponenets/images/peanut.png" alt="Peanut" />
        </figure>
      </div>
    </div>
  </Layout>
)

export default OfficialRules
